import { EventEmitter, Injectable, inject } from '@angular/core';
import {
  AddPlaylistsToChannelsGQL,
  AssignChannelPlaylistRegionGQL,
  Channel,
  ChannelsForChannelListFragment,
  CreateChannelGQL,
  CreateChannelInput,
  Device,
  GetChannelGQL,
  GetChannelPlaylistsGQL,
  GetProfileChannelsForChannelListGQL,
  Maybe,
  Playlist,
  PlaylistStatus,
  PublishChannelsContentGQL,
} from '@designage/gql';
import { IPlaylistRegion } from '@desquare/interfaces';
import {
  Observable,
  lastValueFrom,
  map,
  filter,
  catchError,
  of,
  switchMap,
  BehaviorSubject,
} from 'rxjs';
import { CurrentUserService } from '../current-user/current-user.service';
import { GraphQLFormattedError } from 'graphql';
import { ToasterService } from '../toaster/toaster.service';

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  publishedPlaylistsForCurrentChannelChanged = new EventEmitter();
  assignChannelPlaylistRegionGQL = inject(AssignChannelPlaylistRegionGQL);
  currentUserService = inject(CurrentUserService);
  createChannelGQL = inject(CreateChannelGQL);
  publishChannelsContentGql = inject(PublishChannelsContentGQL);
  addPlaylistsToChannelsGql = inject(AddPlaylistsToChannelsGQL);
  getChannelGQL = inject(GetChannelGQL);
  getProfileChannelsGql = inject(GetProfileChannelsForChannelListGQL);
  toasterService = inject(ToasterService);

  channelId = new BehaviorSubject<string | null>(null);

  private getChannelPlaylistsGQL = inject(GetChannelPlaylistsGQL).watch(
    {
      channelId: this.channelId.value ?? '',
      onlyParentPlaylist: true,
      statuses: [PlaylistStatus.Active],
    },
    { fetchPolicy: 'no-cache' },
  );

  async getCurrentProfileChannels() {
    const profileId = this.currentUserService.getCurrentProfileId();
    return await this.getProfileChannelsList(profileId);
  }

  async getProfileChannelsList(profileId: string) {
    const { data } = await lastValueFrom(
      this.getProfileChannelsGql.fetch({ id: profileId }),
    );
    return data.profile?.channels as Maybe<ChannelsForChannelListFragment>[];
  }

  channelsFromApi$ = inject(GetProfileChannelsForChannelListGQL).watch(
    {
      id: inject(CurrentUserService).getCurrentProfileId(),
    },
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
  );

  getChannelsFromApi$(): Observable<{
    channels: Channel[];
    loading: boolean;
    errors: readonly GraphQLFormattedError<Record<string, any>>[] | undefined;
  }> {
    return this.channelsFromApi$.valueChanges.pipe(
      map(({ data, errors, loading }) => {
        const channels: Channel[] = (data?.profile?.channels || []).map(
          (channel) => ({
            ...channel,
            devices: channel.devices.map((device) => ({
              ...device,
            })) as Device[],
            playlists: channel.playlists.map((playlist) => ({
              ...playlist,
            })) as Playlist[],
          }),
        );
        return {
          channels,
          loading,
          errors,
        };
      }),
    );
  }

  refreshChannels() {
    this.channelsFromApi$.refetch();
  }

  getChannelPlaylistsFromApi$(): Observable<{
    playlists: Playlist[];
    loading: boolean;
    errors: readonly GraphQLFormattedError<Record<string, any>>[] | undefined;
  }> {
    return this.channelId.pipe(
      filter((channelId) => !!channelId),
      switchMap((channelId) => {
        this.getChannelPlaylistsGQL.refetch({
          channelId: channelId!,
        });
        return this.getChannelPlaylistsGQL.valueChanges;
      }),
      map(({ data, loading, errors }) => ({
        playlists: this.sortPlaylists(
          (data?.channel?.playlists || []) as Playlist[],
        ),
        loading,
        errors,
      })),
      catchError((error) =>
        of({
          playlists: [],
          loading: false,
          errors: [{ message: error.message } as GraphQLFormattedError],
        }),
      ),
    );
  }

  private sortPlaylists(playlists: Playlist[]): Playlist[] {
    return playlists
      .sort((a, b) => {
        // First sort by type (EXCLUSIVE first)
        if (a.type === 'EXCLUSIVE' && b.type !== 'EXCLUSIVE') return -1;
        if (b.type === 'EXCLUSIVE' && a.type !== 'EXCLUSIVE') return 1;

        // Then sort alphabetically by name
        return (a.name || '').localeCompare(b.name || '');
      })
      .map((playlist) => ({
        ...playlist,
        assets: [...(playlist.assets || [])].sort((a, b) => {
          if (a.sequence && b.sequence && a.sequence > b.sequence) {
            return 1;
          }
          return -1;
        }),
      }));
  }

  async refreshChannelPlaylists(channelId?: string) {
    if (channelId) {
      await this.getChannelPlaylistsGQL.refetch({ channelId });
    } else {
      await this.getChannelPlaylistsGQL.refetch();
    }
  }

  async updateRegionGql(channelId: string, input: IPlaylistRegion) {
    const { playlistId, region } = input;
    await lastValueFrom(
      this.assignChannelPlaylistRegionGQL.mutate({
        input: { channelId, playlistId, region },
      }),
    )
      .then(() => {
        this.refreshChannels();
        this.refreshChannelPlaylists(channelId);
        this.toasterService.success('REGION_UPDATE_SUCCESS');
      })
      .catch((error) => {
        console.error('updateRegion', error);
        this.toasterService.handleGqlError(error as GraphQLFormattedError);
      });
  }
  async getChannel(channelId: string) {
    const { data } = await lastValueFrom(
      this.getChannelGQL.fetch(
        { id: channelId },
        {
          fetchPolicy: 'network-only',
        },
      ),
    );
    return data.channel;
  }

  async addPlaylistsToChannels(
    channelIds: string[],
    playlistIds: string[],
    region = '',
    publish = false,
  ) {
    const { data } = await lastValueFrom(
      this.addPlaylistsToChannelsGql.mutate({
        input: {
          playlistIds,
          channelIds,
          region,
          publish,
        },
      }),
    );
    this.getChannelPlaylistsGQL.refetch();
    return data?.addPlaylistsToChannels;
  }

  createChannel(input: CreateChannelInput) {
    return this.createChannelGQL.mutate(
      { input },
      /* not really usable here
      {
        refetchQueries: [
          {
            query: GetEventsListDocument,
            variables: {
              profileId: input.profileId,
              // deviceId: this.deviceId,
            },
          },
        ],
      }*/
    );
  }

  // async getChannelPlaylists(
  //   channelId: string,
  //   statusFilters: PlaylistStatus[] = [PlaylistStatus.Active],
  // ) {
  //   const { data } = await lastValueFrom(
  //     this.getChannelPlaylistsGQL.fetch(
  //       {
  //         channelId,
  //         playlistName: '',
  //         statuses: statusFilters,
  //       },
  //       { fetchPolicy: 'network-only' },
  //     ),
  //   );
  //   return (data.channel?.playlists as Playlist[]) || [];
  // }

  async republishChannelContent(channelIds: string[]) {
    const { data } = await lastValueFrom(
      this.publishChannelsContentGql.mutate({
        input: {
          channelIds,
        },
      }),
    );

    return data?.publishChannels;
  }
}
