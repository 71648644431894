import {
  signalStore,
  withState,
  withComputed,
  withMethods,
  patchState,
  withHooks,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { ChannelService, ToasterService } from '@desquare/services';
import { Signal, inject, signal } from '@angular/core';
import { distinctUntilChanged, pipe, switchMap } from 'rxjs';
import { GraphQLFormattedError } from 'graphql';
import { Channel, Playlist } from '@designage/gql';
import { IPlaylistRegion } from '@desquare/interfaces';

export interface IChannelSignal {
  channels: Channel[];
  selectedChannelId: string;
  channelPlaylists: Playlist[];
  loading: boolean;
  errors: GraphQLFormattedError<Record<string, any>>[] | null;
}

const initialState: IChannelSignal = {
  channels: [],
  selectedChannelId: '',
  channelPlaylists: [],
  loading: true,
  errors: null,
};

export const ChannelsStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),

  withMethods(
    (
      store,
      channelService = inject(ChannelService),
      toasterService = inject(ToasterService),
    ) => {
      const getChannelsFromApi = rxMethod<void>(
        pipe(
          distinctUntilChanged(),
          switchMap(() => {
            return channelService.getChannelsFromApi$().pipe(
              tapResponse({
                next: ({ channels, errors, loading }) => {
                  patchState(store, {
                    channels,
                    loading,
                    errors: errors ? [...errors] : [],
                  });
                  if (
                    store.channelPlaylists().length === 0 &&
                    store.channels().length > 0
                  ) {
                    channelService.channelId.next(store.channels()[0].id);
                    getChannelPlaylistsFromApi();
                  }
                },
                error: console.error,
                finalize: () => patchState(store, { loading: false }),
              }),
            );
          }),
        ),
      );
      const getChannel = (channelId: string): Signal<Channel | undefined> => {
        const channel = signal<Channel | undefined>(
          store.channels().find((x) => x.id === channelId),
        );
        return channel;
      };
      const getChannelPlaylistsFromApi = rxMethod<void>(
        pipe(
          distinctUntilChanged(),
          switchMap(() => {
            return channelService.getChannelPlaylistsFromApi$().pipe(
              tapResponse({
                next: ({ playlists, errors, loading }) => {
                  patchState(store, {
                    channelPlaylists: playlists,
                    loading,
                    errors: errors ? [...errors] : [],
                  });
                },
                error: console.error,
                finalize: () => patchState(store, { loading: false }),
              }),
            );
          }),
        ),
      );
      const refreshChannelPlaylists = async (channelId: string) => {
        if (
          store.channels().length === 0 ||
          store.channelPlaylists().length === 0
        )
          return;
        console.log('getChannelPlaylists', channelId);
        await channelService.refreshChannelPlaylists(channelId);
      };
      const getChannelsById = (channelIds: string[]): Signal<Channel[]> => {
        const channels = signal<Channel[]>([]);
        channels.update(() =>
          channelIds.reduce((state, ids) => {
            const foundDevice = store.channels().find((x) => x.id === ids);
            return foundDevice ? [...state, foundDevice] : state;
          }, [] as Channel[]),
        );
        return channels;
      };
      const updateChannel = (channel: Channel) => {
        patchState(store, {
          channels: store.channels().map((x) => {
            if (x.id === channel.id) {
              return { ...x, ...channel };
            } else {
              return x;
            }
          }),
        });
      };
      const addChannel = (channel: Channel) => {
        patchState(store, {
          channels: [...store.channels(), channel],
        });
      };
      const removeChannel = (channelId: string) => {
        patchState(store, {
          channels: store.channels().filter((x) => x.id !== channelId),
        });
      };
      const updateRegion = async (
        channelId: string,
        input: IPlaylistRegion,
      ) => {
        const { playlistId, region } = input;
        await channelService.updateRegionGql(channelId, { playlistId, region });
        channelService.refreshChannelPlaylists(channelId);
      };

      return {
        getChannelsFromApi,
        getChannel,
        getChannelPlaylistsFromApi,
        refreshChannelPlaylists,
        getChannelsById,
        updateChannel,
        addChannel,
        removeChannel,
        updateRegion,
      };
    },
  ),
  withHooks((store) => ({
    onInit() {
      // Load products when the store is initialized
      store.getChannelsFromApi();
      store.getChannelPlaylistsFromApi();
    },
  })),
  withComputed((state) => ({
    // channelsByLocations: (locationId: string): Signal<Channel[]> => {
    //   return state.channels().filter((x) => x. === locationId);
    // },
  })),
);
