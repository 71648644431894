// Topic general composition schema:
// ROOT / TOPIC_NAME / [profileId / ] deviceId / MSGTYPE
//
// topic schema
// /designage            -> ROOT
//      /devices2api  -> device publishes, api subscribes
//            /deviceId
//                /status -> a device will send ON or OFF as online/offline status
//      /api2devices  -> device subscribes, api publishes
//            /deviceId
//                /cmd -> command to device like "send a new screenshot now!"
//      /api2wt     -> devices publishes per profile (eg: Watchtower)
//            /profileId
//                  /deviceId
//      /img
//            /deviceId -> base64 screenshot

import { environment } from '@desquare/environments';

// Root
const TOPIC_ROOT = environment.mqtt.root;
const TOKEN_SEPARATOR = '/';

/** *******************************************************************
 * AFTER THIS LINE the file content is shared between all the projects
 **********************************************************************/

// Topic names
const TOPIC_DEVICE_SENDS = 'appletSnd';
const TOPIC_DEVICE_RECEIVES = 'appletRcv';
const TOPIC_API_2_BROADCAST = 'api2bc';
const TOPIC_API_2_CMS = 'api2cms';
const TOPIC_SCREENSHOT = 'shots';
const TOPIC_LOG = 'log';

// Message types
const TOPIC_MSGTYPE_STATUS = 'status';
const TOPIC_MSGTYPE_PING = 'ping';
const TOPIC_MSGTYPE_IMG = 'img';
const TOPIC_MSGTYPE_CMD = 'cmd';
const TOPIC_MSGTYPE_DEVINFO = 'info';
const TOPIC_MSGTYPE_LOG = 'log';
const TOPIC_MSGTYPE_CMS_COMMAND = 'cmscmd';
/**
 * All message types
 */
const MSG_TYPES = {
  TOPIC_MSGTYPE_STATUS,
  TOPIC_MSGTYPE_PING,
  TOPIC_MSGTYPE_IMG,
  TOPIC_MSGTYPE_CMD,
  TOPIC_MSGTYPE_DEVINFO,
  TOPIC_MSGTYPE_LOG,
  TOPIC_MSGTYPE_CMS_COMMAND,
};
// Message values
const CMD_LIVE_SCREENSHOT = 'liveshot';
const CMD_SAVE_SCREENSHOT = 'saveshot';
const CMD_REFRESH_PLAYLISTS = 'refresh';
const CMD_GET_LIVE_LOG = 'getlog';
const CMD_CLEAR_CACHE = 'forcerefresh';
const CMD_TOGGLE_SCREEN_POWER = 'togglescreenpower';
const CMD_REBOOT = 'reboot';
const CMD_APP_RELOAD = 'appletreload';
const CMD_REFRESH_INFO = 'getinfo';
const CMD_SET_TIMEZONE = 'settimezone';
const CMD_SET_BRIGHTNESS = 'setbrightness';
const CMD_SET_VOLUME = 'setvolume';

const STATUS_ONLINE = 'ON';
const STATUS_OFFLINE = 'OFF';
const S3_SCREENSHOT_UPDATED = 's3shotupd';
const CMS_COMMAND_REFRESH_PLAYLISTS = 'refreshPlaylists';
const CMS_COMMAND_REFRESH_CHANNELS = 'refreshChannels';
const CMS_COMMAND_REFRESH_MEDIA = 'refreshMedia';
const CMS_COMMAND_REFRESH_DEVICES = 'refreshDevices';

export type CmsCommandType =
  | typeof CMS_COMMAND_REFRESH_PLAYLISTS
  | typeof CMS_COMMAND_REFRESH_CHANNELS
  | typeof CMS_COMMAND_REFRESH_MEDIA
  | typeof CMS_COMMAND_REFRESH_DEVICES;

export interface ICmsCommand {
  command: CmsCommandType;
  fromUserId: string | null | undefined;
  fromDisplayName: string | null | undefined;
  itemId: string | null | undefined;
}

/**
 * All message constant payloads
 */
const MSG_PAYLOADS = {
  STATUS_ONLINE,
  STATUS_OFFLINE,
  CMD_LIVE_SCREENSHOT,
  CMD_SAVE_SCREENSHOT,
  CMD_REFRESH_PLAYLISTS,
  CMD_GET_LIVE_LOG,
  S3_SCREENSHOT_UPDATED,
  CMD_CLEAR_CACHE,
  CMD_TOGGLE_SCREEN_POWER,
  CMD_REBOOT,
  CMD_APP_RELOAD,
  CMD_REFRESH_INFO,
  CMD_SET_TIMEZONE,
  CMD_SET_VOLUME,
  CMD_SET_BRIGHTNESS,
  CMS_COMMAND_REFRESH_PLAYLISTS,
  CMS_COMMAND_REFRESH_CHANNELS,
  CMS_COMMAND_REFRESH_MEDIA,
  CMS_COMMAND_REFRESH_DEVICES,
};

/**
 * Api ping topic
 */
function topicPingPub() {
  return composeTopic([TOPIC_ROOT, TOPIC_API_2_BROADCAST, TOPIC_MSGTYPE_PING]);
}

/**
 * Api to CMS topic
 */
function topicApi2Cms() {
  return composeTopic([TOPIC_ROOT, TOPIC_API_2_CMS, TOPIC_MSGTYPE_CMS_COMMAND]);
}

/** topic where Applet publishes device's info */
function deviceInfoTopic(deviceId: string, profileId: string) {
  return composeTopic([
    TOPIC_ROOT,
    TOPIC_DEVICE_SENDS,
    profileId,
    deviceId,
    TOPIC_MSGTYPE_DEVINFO,
  ]);
}
/**
 * topic to publish screenshots
 *
 * @param deviceId
 */
function topicScreenshotImg(profileId: string, deviceId: string) {
  return composeTopic([
    TOPIC_ROOT,
    TOPIC_SCREENSHOT,
    profileId,
    deviceId,
    TOPIC_MSGTYPE_IMG,
  ]);
}

/**
 * extract deviceId from a topic
 *
 * @param topic
 */
function topic2DeviceId(topic: string) {
  const tokens = topic.split(TOKEN_SEPARATOR);
  switch (tokens[1]) {
    case TOPIC_DEVICE_RECEIVES:
      return tokens[2];
    case TOPIC_DEVICE_SENDS:
      return tokens[3];
  }
  return tokens[3];
}

/**
 * topic where api pubs devices statuses (with profileId)
 * Watchtower subs (on wildcard profileId)
 *
 * @param profileId
 * @param deviceId
 */
function profileStatusTopic(profileId: string, deviceId: string) {
  return composeTopic([
    TOPIC_ROOT,
    TOPIC_DEVICE_SENDS,
    profileId,
    deviceId,
    TOPIC_MSGTYPE_STATUS,
  ]);
}

function logTopic(profileId: string, deviceId: string) {
  return composeTopic([
    TOPIC_ROOT,
    TOPIC_DEVICE_SENDS,
    profileId,
    deviceId,
    TOPIC_LOG,
  ]);
}

/**
 * profile wildcard topic (subscribe only)
 *
 * @param profileId
 */
function allProfile2CmsTopic(profileId: string) {
  return composeTopic([TOPIC_ROOT, TOPIC_API_2_CMS, profileId, '#']);
}
/**
 * profile wildcard topic (subscribe only)
 *
 * @param profileId
 */
function allProfileDevicesTopic(profileId: string) {
  return composeTopic([TOPIC_ROOT, TOPIC_DEVICE_SENDS, profileId, '#']);
}
/**
 * profile wildcard topic (subscribe only)
 *
 * @param profileId
 */
function allProfileScreenshotsTopic(profileId: string) {
  return composeTopic([TOPIC_ROOT, TOPIC_SCREENSHOT, profileId, '#']);
}
/**
 * wildcard topic (subscribe only)
 */
function allDevices2ServerTopic() {
  return composeTopic([TOPIC_ROOT, TOPIC_DEVICE_SENDS, '#']);
}
/**
 * topic where a device pubs its status and server subs
 *
 * @param deviceId
 */
function deviceStatusTopic(deviceId: string) {
  return composeTopic([
    TOPIC_ROOT,
    TOPIC_DEVICE_SENDS,
    deviceId,
    TOPIC_MSGTYPE_STATUS,
  ]);
}

function deviceLogTopic(profileId: string, deviceId: string) {
  return composeTopic([
    TOPIC_ROOT,
    TOPIC_DEVICE_SENDS,
    profileId,
    deviceId,
    TOPIC_MSGTYPE_LOG,
  ]);
}

/**
 * Device commands topic
 *
 * @param deviceId
 */
function deviceCommandTopic(deviceId: string) {
  return composeTopic([
    TOPIC_ROOT,
    TOPIC_DEVICE_RECEIVES,
    deviceId,
    TOPIC_MSGTYPE_CMD,
  ]);
}
/**
 * topic to send commands to a selected device
 *
 * @param deviceId
 * @param cmd
 */
function server2deviceTopic(deviceId: string, cmd: string) {
  return composeTopic([TOPIC_ROOT, TOPIC_DEVICE_RECEIVES, deviceId, cmd]);
}

function composeTopic(tokens: string[]) {
  let topic = '';
  let sep = '';
  for (const token of tokens.filter((t) => t.length > 0)) {
    // topic += TOKEN_SEPARATOR + token;
    topic = topic.concat(sep).concat(token);
    sep = TOKEN_SEPARATOR;
  }
  return topic;
}

/**
 * get TopicInfo for advanced operations on topic recognition
 *
 * @param topic
 */
function getTopicInfo(topic: string) {
  return new TopicInfo(topic);
}

/**
 * Topic generation and recognition logic
 */
export const TopicLogic = {
  MSG_PAYLOADS,
  MSG_TYPES,
  topicPingPub,
  topicScreenshotImg,
  topic2DeviceId,
  topicApi2Cms,
  profileStatusTopic,
  deviceStatusTopic,
  deviceLogTopic,
  deviceCommandTopic,
  allDevices2ServerTopic,
  allProfileDevicesTopic,
  allProfileScreenshotsTopic,
  allProfile2CmsTopic,
  server2deviceTopic,
  getTopicInfo,
  deviceInfoTopic,
  logTopic,
};

export class TopicInfo {
  tokens: string[];
  constructor(topic: string) {
    this.tokens = topic.split(TOKEN_SEPARATOR);
  }
  get MessageType(): string {
    // msg type is last token
    return this.tokens[this.tokens.length - 1];
  }
  get DeviceId(): string {
    switch (this.tokens[1]) {
      case TOPIC_DEVICE_SENDS:
        // 0=root, 1=^, 2=profileId, 3=deviceId
        return this.tokens[3];
      case TOPIC_SCREENSHOT:
        // 0=root, 1=^, 2=profileId 3=deviceId
        return this.tokens[3];
      default:
    }

    return '';
  }
}
